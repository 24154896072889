.allDataParent{
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: auto auto auto;
    width: 80%;
    margin: auto;
}

.indivBike{
    /* border: 1px solid rgb(173, 168, 168); */
    margin: 20px;
    padding-top: 10px;
    border-radius: 10px;
    background-color: rgb(245,245,245);
}

.indivBike > h3{
    margin-top: 20px;
    font-weight: bold;
}

.indivBike > h2{
    font-size: 16px;
    font-weight: bold;
}

.indivBike > img{
    text-decoration: 1px solid gray underline;
}

.indivBike > button{
    width: 100%;
    background-color: rgb(254,200,38);
    outline: none;
    border: 0;
    cursor: pointer;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
}

.sortsDiv{
    /* border: 1px solid black; */
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

.sortsDiv p:nth-child(1){
    margin-left: 2.5%;
    font-weight: bold;
    text-decoration: none;
    font-size: 15px;
}

.sortsDiv p:nth-child(2),
.sortsDiv p:nth-child(3),
.sortsDiv p:nth-child(4){
    margin-left: 2%;
    cursor: pointer;
    border: 1px solid rgba(192, 188, 188, 0.479);
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
}

.sortsDiv p:nth-child(2):hover{border: 0; background-color: rgb(254,200,38);color: white;}
.sortsDiv p:nth-child(3):hover{border: 0; background-color: rgb(254,200,38);color: white;}
.sortsDiv p:nth-child(4):hover{border: 0; background-color: rgb(254,200,38);color: white;}


.timeAndDateDiv{
    /* border: 1px solid black; */
    width: 76%;
    margin: auto;
    text-align: left;
  
}

.timeAndDateDiv > h2{
    font-weight: bold;
    font-size: 22px;
    line-height: normal;
    color: rgb(254,200,38);
    margin-top: 10px;
}

.timeAndDateDiv > h3{
    font-size: 18px;
}