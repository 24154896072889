.ProfileDropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 2px 10px rgb(204, 202, 202);
}

.ProfileDropdown > div {
  padding: 20px;
}

.ProfileDropdown > div:hover {
  background-color: rgb(184, 169, 169);
  border-radius: 5px;
}

.ProfileDropdown hr {
  margin: 0;
  padding: 0;
}

.ProfileDropdown > div a {
  text-decoration: none;
  color: rgb(29, 27, 27);
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  font-size: 15px;
}
