.text__part {
  box-shadow: 8px 8px 16px rgb(190, 187, 187);

  border-radius: 5px;
  text-align: left;
  padding: 10px;
}

.date__time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 2px;
  font-size: 15px;
  text-align: left;
}
.date__time input {
  border: 0;
  outline: none;
  border-bottom: 1px solid black;
  width: 100%;
}
.bikes__filter__page {
  /* border: 1px solid #9e9e9e; */

  border-radius: 5px;
  text-align: left;
  padding: 10px;
  /* overflow-y: scroll; */
  /* height: 30vh; */
}
.bikes__filter__page label {
  color: #9e9e9e;
}
.apply_filter_btn {
  background-color: #eb952e;
  width: 100%;
  height: 5vh;
  border-radius: 3px;
  border: 0;
  outline: none;
  margin: 2vh auto;
  font-weight: 600;
  border-radius: 5px;
}
.text__part {
  text-align: left;
  padding: 2vh;
  /* overflow-y: scroll;
    height:90vh; */
}

.Checkbox {
  font-size: 18px;
  /* border-bottom: 1px solid rgb(226, 219, 219); */
}

.Checkbox input {
  margin-right: 15px;
}
