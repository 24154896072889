
.contactTopPad{ 
  padding-bottom: 15px;
}

.Home__fleet__head {
  margin: 30px auto 0px;
  width: 400px;
  margin-bottom: 20px;
}

@media (max-width: 426px) {
  .Home__fleet__head {
    width: 100%;
  }
}

.Home__fleet__head h1 {
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 29px;
}

.Home__bar {
  border-bottom: 4px solid #eb952e;
  width: 100px;
  margin: auto;
}

.sec2addr{
  margin: 30px 60px 20px;
  text-align: left; 
}

.sec2addr p{
  font-size: 16px;
}


@media (max-width: 426px) {
  .maps {
    width: 100%;
  }
}