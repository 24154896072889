.navbarLinks {
  border: 1px solid gray;
  background-color: white;
}

.navbar {
  background-color: white;
  align-items: center;
  width: 100%;
}

.homeLogo {
  width: 90px;
  margin: 0px 0px 0px 0px;
}

.homeLogoMob {
  width: 75px;
  margin: 0px 0px 0px 0px;
}

.tarrifLink,
.whatsNewLink,
.offersLink,
.partnerWithUsLink,
.searchLink {
  text-decoration: none;
  margin-right: 0%;
  color: rgb(56, 54, 54);
  padding: 0.8%;
  font-weight: 600;
}

.tarrifLink:hover {
  color: #eb952e;
}
.whatsNewLink:hover {
  color: #eb952e;
}
.offersLink:hover {
  color: #eb952e;
}
.partnerWithUsLink:hover {
  color: #eb952e;
}
.searchLink:hover {
  color: #eb952e;
}

.locationBtn {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  outline: none;
  border: 2px solid #eb952e;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.locationBtn span {
  font-size: 16px;
  font-weight: 100;
  margin: 2px 5px 0px 10px;
}

.locationBtn i {
  margin-top: 6px;
  margin-left: 5px;
}

.profile {
  display: flex;
  align-items: center;
  width: 160px;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  padding: 15px;
  margin: 6px 0 6px 50px;
}

.profile:hover {
  background-color: #eb952e;
}

.profile span {
  font-size: 18px;
  margin-left: 15px;
}

.profile i {
  margin-left: 10px;
  margin-top: 5px;
}

.profile img {
  width: 25%;
}

.currentUser {
  text-transform: capitalize;
}

.waBtn {
  background-color: #33a717;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1.1px;
  line-height: 20px;
  outline: none;
  color: #fff;
}
