 
.Search__element {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Search__element > div {
  width: 50%;
  margin: 15px auto;
  text-align: center;
} 

.Search__element input { 
  color: rgb(80, 75, 75);
}

.Search__element > button {
  width: 100%;
  font-size: 17px;
  padding: 10px;
  margin: 25px auto 25px;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  cursor: pointer;
  background-color: #eb952e;
}
