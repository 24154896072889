.main__cont {
  display: flex;
  justify-content: center;
  border-top: 3px solid #eeeaea;
  margin-top: 10px;
}
.main__cont__filters {
  width: 25vw;
  /* border: 1px solid #9e9e9e; */
  border-radius: 3px;
  margin: 2vh;
  height: 100vh;
}

.main__cont__bikesdata {
  width: 70vw;
  border-radius: 2px;
  border-left: 3px solid #eeeaea;
}
.main__cont__bikesdata__Items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.main__cont__bikesdata__Items img {
  width: 70%;
}
.main__cont__bikesdata__Items > div {
  background-color: #ffffff;
  margin: 2vh;
  width: 19vw;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.bikeItems__availability {
  text-align: center;
  padding: 2px;
  border-top: 3px solid #ebedf2;
  border-bottom: 3px solid #ebedf2;
  padding: 7px 2px;
}
.bikeItems__availability > p {
  font-size: 14px;
  color: grey;
  margin: 3px;
}
.bikeItems__availability > select {
  width: 90%;
  outline: none;
  border: 0px;
  border-bottom: 1px solid black;
  background-color: #f6f6f6;
}
.bikeItems__timings {
  border-bottom: 3px solid #ebedf2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 8vh;
  border-top: 2px soid;
  margin-top: 4px;
}
.bikeItems__timings p {
  /* padding: 3px;
    margin: 0; */
  margin: 0;
}
.bikeItems__timings > div:nth-child(2) {
  padding: 8px 14px;
  border-radius: 50%;
  background-color: black;
  color: white;
}
.bikeItem__booking {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bikeItem__booking p {
  font-size: 14px;
}
.bikeItem__booking button {
  background-color: #eb952e;
  width: 10vw;
  height: 5vh;
  border-radius: 3px;
  border: 0;
  outline: none;
  border-radius: 5px;
  font-weight: 600;
}
.upper__cont {
  margin:5vh;
}
.upper__cont > span {
  margin: 0px 5vh;
}
.upper__cont > button {
  margin: 1px 5vh;
  border: 2px solid #eb952e;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
  background-color: white;
}
