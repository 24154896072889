@import "~antd/dist/antd.css";

@media (min-width: 426px) {
  /* class-name-to-show-mobile-hide-desktop */
  .display-sm-hd {
    display: none;
  }
  .display-sd-hm {
    display: block;
  }
}
@media (max-width: 425px) {
  /* class-name-to-show-desktop-hide-mobile */
  .display-sd-hm {
    display: none;
  }
  .display-sm-hd {
    display: block;
  }
}
.App {
  text-align: center;
}

* {
  font-family: "Roboto", sans-serif;
}

.rcb-ml-auto {
  margin-left: auto;
}

.rcb-ta-center {
  text-align: center;
}

.rcb-ta-initial {
  text-align: initial;
}

.rcb-wa-icon {
  width: 20px;
  height: 15px;
  position: relative;
  top: 2px;
}

.rcb-ml-5 {
  margin-left: 5px;
}

.rcb-mr-5 {
  margin-right: 5px;
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 10pt;
  margin-left: 0in;
}
.MsoPapDefault {
  margin-bottom: 10pt;
}
.WordSection1 {
  size: 8.5in 11in;
  margin: 0.5in 1in 1in 1in;
}

.ant-drawer-header-title {
  justify-content: flex-end;
}

.ant-drawer-close {
  font-size: 21px;
  color: #9f9e9e;
}
@media (max-width: 425px) {
  .nav-menu {
    padding: 0px !important;
  }
  .WordSection1 {
    margin: 10px;
  }
  .nav-header {
    min-height: 75px !important;
  }
}

iframe {
  pointer-events: none;
}