.Footer {
  background-color: #373737;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: left;
  padding: 0px 50px;
}

.Footer__desc h1 {
  font-size: 2em !important;
  line-height: 30px;
  color: white !important;
  font-size: 28px;
}

.Footer h4 { 
  line-height: 15px;
  color: white;
  text-transform: uppercase;
}

.Footer > div {
  width: 100%;
  max-width: 300px;
  color: #dbdbdb;
  padding: 25px;
}

.Footer p {
  font-size: 14px;
}

.Footer__flex {
  display: flex;
  align-items: center;
}

.Footer__flex img {
  margin: 10px;
}

.Footer__highlight {
 width: 90%;
    background-color: #eb952e;
    height: 1px;
    margin: 5px 0 15px;
}
.Footer__desc {
  width: 100%;
  padding: 50px;
  background-color: #141414;
  text-align: left;
}

.Footer__desc h2 {
  line-height: 30px;
  color: white;
  font-size: 28px;
}

.Footer__desc p {
  color: #dbdbdb;
  font-size: 17px;
}

.Footer__end {
  background-color: #373737;
  display: flex;
  align-items: end;
  justify-content: end;
  padding-top: 10px;
  padding-right: 20px;
}

.Footer__end img {
  margin: 0 10px;
}

.Footer__end h5 {
  color: white;
  letter-spacing: 1.2px;
}

.Footer  a { 
  color: #dbdbdb;
}